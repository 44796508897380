import React from 'react';
import styled from 'styled-components';
import {graphql } from 'gatsby';
import { Link, useTranslation } from 'gatsby-plugin-react-i18next';
import { GoogleReCaptchaProvider } from 'react-google-recaptcha-v3';
import Layout from '../components/Landing/layout';
import Seo from '../components/seo';
import { HOME } from '../routes';
import DodForm from '../components/DodForm';
import bgSrc from '../images/img-bg.jpg';
import logoSrc from '../images/landing/logo-pod-rimskym-vrchem-light.png';
import { RECAPTCHA_SITE_KEY } from '../config';

const ContentWrapper = styled.div`
  position: relative;
  max-width: 1300px;
  margin: 0 auto;
  z-index: 2;
  padding-right: 15px;
  padding-left: 15px;
`;

const Content = styled.div`
  margin: 2rem 0 1rem;
  display: flex;
  flex-direction: column;
  align-items: center;

  @media (min-width: 768px) {
    margin: 4rem 0 2rem;
  }
`;

const Section = styled.div`
  background-color: #ffffff;
  background-image: url(${bgSrc});
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center center;
  padding: 3.5rem 0;
  position: relative;
  min-height: 100vh;

  &:after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: #303030;
    opacity: 0.60;
    z-index: 0;
  }

  @media (min-width: 768px) {
    padding: 6.25rem 0;
  }
`;

const LogoWrapper = styled.div`
  text-align: center;

  img {
    width: 150px;
    height: auto;
    margin-bottom: 2rem;

    @media (min-width: 992px) {
      width: 200px;
    }

    @media (min-width: 1400px) {
      width: 300px;
    }
  }
`;

const TitleWrapper = styled.div`
  p {
    color: #ffffff;
    text-align: center;
    font-size: 1.25rem;
  }

  @media (min-width: 768px) {
    font-size: 1.5rem;
  }
`;

const Title = styled.h2`
  font-family: 'Playfair Display', Georgia, sans-serif;
  font-weight: 700;
  color: #ffffff;
  text-align: center;
  font-size: 1.75rem;
  line-height: 2.25rem;
  z-index: 2;
  margin-bottom: 1rem;

  @media (min-width: 768px) {
    margin-bottom: 0.5rem;
    font-size: 2.5rem;
    line-height: 3rem;
  }
`;

const Divider = styled.span`
`;

const CustomLink = styled.span`
  cursor: pointer;
`;

const Info = styled.div`
  text-align: center;
  ${Divider} {
    display: none;
  }

  span {
    display: block;
    margin-bottom: 0.25rem;
  }

  @media (min-width: 576px) {
    ${Divider} {
      display: inline-block;
    }

    span {
      display: inline-block;
      margin-bottom: 0;
    }
  }
`;

const Footer = styled.div`
  margin-top: 2rem;
  color: #bcbec0;
  font-size: 0.875rem;

  a, ${CustomLink} {
    text-decoration: underline;
    color: #bcbec0;
  }

  a:hover, ${CustomLink}:hover {
    color: #bcbec0;
    text-decoration: none;
  }
`;

const FormWrapper = styled.div`
  margin: 0 auto 1rem;
  width: 100%;

  @media (min-width: 768px) {
    margin: 4rem auto 2rem;
    max-width: 500px;
  }

`;

function DodPage() {
  const { t } = useTranslation();

  return (
    <Layout showPageTitle>
      <Seo title={t('seo.dod.title')} />
      <Section>
        <ContentWrapper>
          <LogoWrapper>
            <Link to={HOME}>
              <img src={logoSrc} alt={t('logo_alt')} />
            </Link>
          </LogoWrapper>

          <Content>
            <TitleWrapper>
              <Title>{t('dod.title')}</Title>
              <p>{t('dod.subtitle')}</p>
            </TitleWrapper>

            <FormWrapper>
              <GoogleReCaptchaProvider reCaptchaKey={RECAPTCHA_SITE_KEY} language="cs">
                <DodForm />
              </GoogleReCaptchaProvider>
            </FormWrapper>

          </Content>
          <Footer>
            <Info>
              <span>&copy; {t('footer.info_text_1')}</span> <Divider>|</Divider> <span><a href={t('footer.privacy_policy_link')} target="_blank" rel="noreferrer" title={t('footer.privacy_policy_link_info')}>{t('footer.privacy_policy')}</a></span>
              <Divider>|</Divider> <CustomLink id="c-settings" aria-haspopup="dialog">{t('cookies.btn_settings')}</CustomLink>
            </Info>
          </Footer>
        </ContentWrapper>

      </Section>
    </Layout>
  );
}

export default DodPage;

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(filter: {language: {eq: $language}}) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;