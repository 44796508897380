import React, { useState } from 'react';
import styled from 'styled-components';
import { useTranslation } from 'gatsby-plugin-react-i18next';
import axios from 'axios';
import { useGoogleReCaptcha } from 'react-google-recaptcha-v3';
import { setIn } from 'final-form';
import { Form, Field } from 'react-final-form';
import * as yup from 'yup';
import { globalHistory } from "@reach/router";
import { MESSAGE_URL } from '../../config';

const validationSchema = yup.object({
  name: yup.string().nullable(true).required('validation_required'),
	email: yup.string().nullable(true).required('validation_required').email('validation_email'),
  phone: yup.string().nullable(true).required('validation_required'),
  visitors: yup.string().nullable(true).required('validation_required'),
});

const validateFormValues = (schema) => async (values) => {
	if (typeof schema === 'function') {
		schema = schema();
	}
	try {
		await schema.validate(values, { abortEarly: false });
	} catch (err) {
		const errors = err.inner.reduce((formError, innerError) => {
			return setIn(formError, innerError.path, innerError.message);
		}, {});

		return errors;
	}
};

const validate = validateFormValues(validationSchema);

const Input = styled.input`
  height: 50px;
  padding: 0 0.75rem;
  border: 0;
  width: 100%;

  border: 2px solid ${({theme, hasError}) => hasError ? '#d50000' : theme.colors.white};

  &:focus {
    outline: 0;
    padding: 0 0.75rem;
  }

  @media(min-width: 992px) {
    height: 60px;
    padding: 0 0.75rem;
  }
`;

const InputWrapper = styled.div`
`;

const ErrorMessage = styled.p`
  font-size: 0.75rem;
  line-height: 1.25rem;
  background-color: #d50000 !important;
  padding-left: 0.75rem;
  margin-bottom: 0;
  color: #fefefe;
  margin-bottom: 0 !important;
`;

const SubmitError = styled.p`
  font-size: 1em;
  line-height: 1.25rem;
  background-color: #d50000 !important;
  margin-bottom: 0;
  padding: 0.75rem;
  color: #fefefe;
  margin-bottom: 0 !important;
`;

const SuccessMessage = styled(SubmitError)`
  background-color: #55a157 !important;
`;


const TextareaWrapper = styled.div`
  input {
    width: 100%;
  }
`;

const ButtonWrapper = styled.div`
  padding-top: 1rem;
  text-align: center;

  button {
    font-size: 1rem;
    text-transform: uppercase;
    border: 0;
    background-color: #ff940a;
    color: #fefefe;
    padding: 0.75rem;
    width: 100%;
    box-shadow: -1px 3px 7px rgba(0, 0, 0, 0.72);
    cursor: pointer;

    &:disabled {
      background-color: #939393;
      color: #fefefe;
      cursor: not-allowed;
    }

    &:focus {
      border: 0;
      outline: 0;
    }

    &:hover {
      background-color: #303030;
    }
  }

  @media(min-width: 576px) {
    text-align: right;

    button {
      width: auto;
      min-width: 160px;
    }
  }

`;

const Grid = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  column-gap: 0.75rem;
  row-gap: 0.5rem;
  padding-top: 2.5rem;
  width: 100%;

  form {
    display: contents;
  }

  @media (min-width: 768px) {
    padding-top: 0;
    min-width: 500px;
  }

  @media (min-width: 992px) {
    grid-template-columns: 1fr;
  }

`;

function DodForm() {
  const [showError, setShowError] = useState(false);
  const [isSent, setSent] = useState(false);
  const { executeRecaptcha} = useGoogleReCaptcha();

  const { t } = useTranslation();

  const onSubmit = async (values, form) => {
    const token = await executeRecaptcha("dod_form");

    setShowError(false);
    setSent(false);
    axios.post(`${MESSAGE_URL}/message.php`, {
      ...values,
      token,
      url: globalHistory.location.href,
    })
    .then(response => {
      setShowError(false);
      setSent(true);
      form.restart();
      // window.dataLayer.push({'event': `formSubmit-${elementId}`});
    })
    .catch(error => {
      setShowError(true);
      setSent(false);
    })
  }

  return (
    <Grid>
       <Form
      onSubmit={(values, form) => onSubmit(values, form)}
      initialValues={{
        name: '',
        email: '',
        phone: '',
        visitors: '',
      }}
      validate={validate}
      render={({ handleSubmit, submitting}) => (
        <form onSubmit={handleSubmit}>
         <Field
            name="name"
            type="text"
          >
            {({ input, meta }) => (
              <InputWrapper>
                <Input {...input} hasError={meta.error && meta.touched} placeholder={t('dod.form.name')} />
                {meta.error && meta.touched && <ErrorMessage>{t(`form.${meta.error}`)}</ErrorMessage>}
              </InputWrapper>
            )}
          </Field>
          <Field
            name="phone"
            type="text"
          >
            {({ input, meta }) => (
              <InputWrapper>
                <Input {...input} hasError={meta.error && meta.touched} placeholder={t('dod.form.phone')} />
                {meta.error && meta.touched && <ErrorMessage>{t(`form.${meta.error}`)}</ErrorMessage>}
              </InputWrapper>
            )}
          </Field>
      <TextareaWrapper>
        <Field
          name="email"
          type="text"
        >
          {({ input, meta }) => (
            <InputWrapper>
              <Input {...input} hasError={meta.error && meta.touched} placeholder={t('dod.form.email')} />
              {meta.error && meta.touched && <ErrorMessage>{t(`form.${meta.error}`)}</ErrorMessage>}
            </InputWrapper>
          )}
        </Field>
      </TextareaWrapper>
      <TextareaWrapper>
        <Field
          name="visitors"
          type="text"
        >
          {({ input, meta }) => (
            <InputWrapper>
              <Input {...input} hasError={meta.error && meta.touched} placeholder={t('dod.form.visitors')} />
              {meta.error && meta.touched && <ErrorMessage>{t(`form.${meta.error}`)}</ErrorMessage>}
            </InputWrapper>
          )}
        </Field>
      </TextareaWrapper>
      { showError && <SubmitError>{t('dod.form.submit_error')}</SubmitError> }
      { isSent && !showError && <SuccessMessage>{t('dod.form.success_message')}</SuccessMessage> }
      <ButtonWrapper>
        <button type="submit" disabled={submitting || isSent}>{isSent ? t('form.sent') : t('form.send')}</button>
      </ButtonWrapper>
      </form>
       )}
       />
    </Grid>
  );
}

export default DodForm;